<template>
  <div>
    <nav v-resize="onResize">
      <v-app-bar app flat dark color="primary">
        <v-btn
          @click.stop="drawer = !drawer"
          small
          class="d-lg-none"
          fab
          text
          color="white"
        >
          <v-icon>mdi-menu</v-icon>
        </v-btn>

        <v-btn width="40" class="d-none d-sm-block" text disabled fab></v-btn>
        <v-btn width="40" class="d-none d-sm-block" text disabled fab></v-btn>

        <v-spacer></v-spacer>

        <!-- AgentPay Logo -->
        <div>
          <router-link :to="{ name: 'dashboard' }">
            <v-img
              v-if="$store.state.lightTheme"
              src="@/assets/agentPay Logos/Logo/SVG/agentPay - Logo.svg"
              contain
              width="120"
            ></v-img>
            <v-img
              v-else
              src="@/assets/agentPay Logos/Logo/SVG/agentPay - darkLogo.svg"
              contain
              width="120"
            ></v-img>
          </router-link>
        </div>

        <v-spacer></v-spacer>

        <AppSettings ref="AppSettings" class="d-none d-sm-block" />

        <!-- Release notes menu for all screens above Mobile -->
        <TheNavbarReleaseNotesMenu
          :releaseNotes="releaseNotes"
          class="d-none d-sm-block"
        />

        <!-- Account menu (links to: Sign-out etc) -->
        <v-menu offset-y>
          <template v-slot:activator="{ on }">
            <v-btn small fab text v-on="on" color="white">
              <v-icon>mdi-account</v-icon>
            </v-btn>
          </template>
          <v-list class="py-0">
            <v-list-item class="secondary">
              <v-list-item-title>
                <span class="white--text">{{
                  this.$store.state.user.UserDisplayName
                }}</span>
              </v-list-item-title>
            </v-list-item>
            <v-list-item class="d-sm-none" @click="releaseNotesDialog = true">
              <v-list-item-title> Release notes </v-list-item-title>
            </v-list-item>
            <v-list-item class="d-sm-none" @click="openAppSettings">
              <v-list-item-title> Edit dashbaord </v-list-item-title>
            </v-list-item>
            <v-list-item href="https://agentpay.zendesk.com" target="_blank">
              <v-list-item-title> FAQ </v-list-item-title>
            </v-list-item>
            <v-list-item @click="reroute('/agentpay/edits')">
              <v-list-item-title> Edit/Update </v-list-item-title>
            </v-list-item>
            <v-list-group @click.stop no-action>
              <template v-slot:activator @click.stop>
                <v-list-item-content>
                  <v-list-item-title>Add</v-list-item-title>
                </v-list-item-content>
              </template>
              <v-list-item @click="reroute('/agentpay/addTenancy')">
                <v-list-item-title> Tenancy </v-list-item-title>
              </v-list-item>
              <v-list-item @click="reroute('/agentpay/addContractor')">
                <v-list-item-title> Contractor </v-list-item-title>
              </v-list-item>
            </v-list-group>
            <v-list-item
              href="https://agentos.canny.io/agentpay-software-requests"
              target="_blank"
            >
              <v-list-item-title> Suggest a feature </v-list-item-title>
            </v-list-item>
            <v-list-item @click="logout">
              <v-list-item-title>
                <span>Sign out</span>
                <v-icon color="secondary" small right
                  >mdi-logout-variant</v-icon
                >
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-app-bar>
    </nav>

    <v-navigation-drawer
      app
      width="268"
      style="min-width: 73px"
      height="100%"
      dark
      v-model="drawer"
      :mini-variant="$vuetify.breakpoint.lgAndUp"
      :expand-on-hover="$vuetify.breakpoint.lgAndUp"
      s
      overlay-opacity="0.9"
      overlay-color="secondary"
    >
      <v-img
        src="../assets/architecture-buildings-city-1769371.jpg"
        gradient="rgba(27, 27, 27, 0.65), rgba(27, 27, 27, 0.65)"
        height="100%"
      >
        <v-list nav style="height:100%; overflow-y:auto; overflow-x: hidden;" >
          <v-list-item
            v-for="link in navigationLinks"
            :key="link.text"
            link
            :dense="$vuetify.breakpoint.xsOnly"
            router
            :to="link.route"
            active-class="accent white--text"
          >
            <v-list-item-icon>
              <v-icon>{{ link.icon }}</v-icon>
            </v-list-item-icon>

            <v-list-item-content class="ml-n3">
              <v-list-item-title>{{ link.text }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-img>
    </v-navigation-drawer>

    <!-- Latest release dialog for Mobile ONLY -->
    <v-dialog
      v-model="releaseNotesDialog"
      scrollable
      persistent
      max-width="500px"
      transition="dialog-transition"
    >
      <v-card>
        <v-card-title class="secondary white--text py-0">
          Release notes
          <v-spacer></v-spacer>
          <v-btn @click="releaseNotesDialog = false" color="white" text fab>
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="pt-1">
          <div v-for="item in releaseNotes" :key="item.date" class="mb-3">
            <div class="">
              <v-chip
                small
                :color="item.state === 'New' ? 'green' : 'blue'"
                dark
                class="mr-2 my-2"
                >{{ item.state }}</v-chip
              >
              <span class="text-caption">{{ item.date }}</span>
            </div>
            <ol class="text-caption">
              <li v-for="(note, index) in item.notes" :key="index">
                {{ note }}
              </li>
            </ol>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import TheNavbarReleaseNotesMenu from "@/components/TheNavbarReleaseNotesMenu.vue";
import AppSettings from "@/components/AppSettings.vue";

export default {
  components: {
    TheNavbarReleaseNotesMenu,
    AppSettings,
  },
  data() {
    return {
      navDrawerDense: true,
      drawer: null,
      navigationLinks: [
        {
          icon: "mdi-view-dashboard",
          text: "Dashboard",
          route: "/agentpay/dashboard",
        },
        {
          icon: "mdi-transfer",
          text: "Client accounts",
          route: "/agentpay/unreconciled",
        },
        {
          icon: "mdi-arrow-up-bold-box",
          text: "Outgoing",
          route: "/agentpay/outgoing",
        },
        {
          icon: "mdi-file-document-multiple",
          text: "Invoices",
          route: "/agentpay/invoices",
        },
        {
          icon: "mdi-home-city",
          text: "Properties",
          route: "/agentpay/properties",
        },
        {
          icon: "mdi-home-account",
          text: "Tenants",
          route: "/agentpay/tenants",
        },
        {
          icon: "mdi-account-tie",
          text: "Landlords",
          route: "/agentpay/landlords",
        },
        {
          icon: "mdi-tools",
          text: "Contractors",
          route: "/agentpay/contractors",
        },
        {
          icon: "mdi-file-document",
          text: "Reports",
          route: "/agentpay/reports",
        },
        {
          icon: "mdi-bank",
          text: "Open banking",
          route: "/agentpay/open-banking",
        },
        {
          icon: "mdi-currency-gbp",
          text: "Payment Requests",
          route: "/agentpay/payments"
        },
        {
          icon: "mdi-chart-line",
          text: "Forecast",
          route: "/agentpay/forecasts"
        }
      ],
      releaseNotesDialog: false,
      releaseNotes: [
      {
          date: "August - 2023",
          state: "New",
          notes: [
            "Implemented Reconcile Report",
            "Implemented Unpaid Invoices Report",
            "Implemented Management Fee Forecast Report",
            "Implemented Rent Chase SMS functionality",
            "Changed Rent Chase functionality, will now record rent chases when a rent chase is submitted",
            "Fixed a bug with currency values not displaying on report tables",
            "Fixed a bug when using dark theme when requesting an STO where confirmation button was no longer visible",
            "Fixed a bug where a tenancy without a rent schedule would break the tenants in arrears report",
            "Fixed a bug with the side menu where it was not scrollable, so not all options were visible on smaller screens",
            "Versioned app API to improve stability around active development and releases"
          ],
      },  
      {
          date: "September - 2022",
          state: "Previous",
          notes: [
            "Payment Requests: Create/View Payment requests added",
            "Apply Charge functionality added to person records",
            "Table for all outgoing payment records",
            "Historic tenancy and tenant records added"
          ],
        },  
      {
          date: "December - 2021",
          state: "Previous",
          notes: [
            "CONTACT SUPPORT: Added the ability to upload documents when contacting support.",
          ],
        },
        {
          date: "November - 2021",
          state: "Previous",
          notes: [
            "INVOICES: New section added allowing you to create and authorise invoices.",
            "CONTRACTORS: Invite contractors to open an account in the agentOS contractorApp.",
            "OUTGOING PAYMENTS: View and download your last 10 invoices.",
            'RECORD TABLES: All buttons moved under new single "Actions" button.',
            'THEME: New "NOS" dark mode added to application settings.',
          ],
        },
        {
          date: "May - 2021",
          state: "Previous",
          notes: [
            'OUTGOING PAYMENTS: "Pause all payments" button added to table.',
          ],
        },
        {
          date: "January - 2021",
          state: "Previous",
          notes: [
            "DASHBOARD: select between simplified and detailed verisions.",
            'PAYMENTS: Pause and unpause payments - all payments defaulted to "On Hold".',
            "OUTGOING PAYMENTS: Payment status and action buttons added.",
            "NAVIGATION: auto scroll to the top of the page when navigating to a new page.",
            "MOBILE: improved design of table rows on smaller screens",
            'TENANTS: data order defaults to "Days in Debt", with lowest at the top.',
          ],
        },
      ],
    };
  },
  methods: {
    logout() {
      this.$store.dispatch("logout").then(this.$router.push("/"));
    },
    reroute(route) {
      this.$router.push(route);
    },
    toggleTheme() {
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
    },
    onResize() {
      if (window.innerWidth <= 768) {
        this.navDrawerDense = true;
      } else this.navDrawerDense = false;
    },
    openAppSettings() {
      this.$refs.AppSettings.openDialog();
    },
  },
  computed: {
    ...mapGetters(["returnUserFirstName"]),
  },
  mounted() {
    this.onResize();
  },
};
</script>

<style scoped>
.logo {
  filter: brightness(90%);
  border-radius: 10px;
}

.release-note-wrapper {
  border-bottom: 1px solid rgb(216, 216, 216);
}

.spacer-div {
  width: 0;
}
</style>
