<template>
  <div>
    <v-menu offset-y>
      <template v-slot:activator="{ on }">
        <v-btn small fab color="white" v-on="on" text>
          <v-icon>mdi-alert-decagram</v-icon>
        </v-btn>
      </template>
      <v-list class="pt-0">
        <v-list-item class="secondary">
          <v-list-item-title>
            <span class="white--text">Release notes</span>
          </v-list-item-title>
        </v-list-item>
        <v-list-item 
          v-for="item in releaseNotes" 
          :key="item.date"
          class="mb-3"
        >
          <v-list-item-title>
            <v-chip 
              small 
              :color="item.state === 'New' ? 'green' : 'blue'" 
              dark 
              class="mr-2 my-2"
            >{{ item.state }}</v-chip>
            <span class="text-caption">{{ item.date }}</span>
            <ol class="text-caption">
              <li v-for="(note, index) in item.notes" :key="index">{{ note }}</li>
            </ol>
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
export default {
  name: 'the-navbar-release-notes',
  props: {
    releaseNotes: Array // passed down as props because also used in the Navbar component to render a dialog on mobile screens
  },
  data() {
    return {

    }
  },
}
</script>

<style>

</style>