<template>
  <div>
    <!-- 
      Currently allows user to switch between a full and simplified version of the
      dashboard.
      Also allows to switch between a light and dark mode.
     -->

    <v-btn @click="openDialog" color="white" fab small text>
      <v-icon>mdi-view-dashboard-outline</v-icon>
    </v-btn>

    <v-dialog
      v-model="dialog"
      persistent
      width="500px"
      transition="dialog-transition"
    >
      <v-card>
        <template slot="progress">
          <v-progress-linear
            color="secondary"
            indeterminate
          ></v-progress-linear>
        </template>
        <v-card-title
          class="rounded-0 dialogColor white--text font-weight-light"
        >
          <span>App settings</span>
          <v-spacer></v-spacer>
          <v-icon color="white">mdi-view-dashboard-outline</v-icon>
        </v-card-title>
        <v-card-text class="pt-5">
          <v-row align="center">
            <v-col cols="4" class="d-flex align-center justify-center">
              <div class="d-flex flex-column">
                <v-icon v-if="!alternativeDashboardBoolean" x-large
                  >mdi-view-dashboard-variant</v-icon
                >
                <v-icon color="grey lighten-2" v-else x-large
                  >mdi-view-dashboard-variant-outline</v-icon
                >
                <span
                  class="text-h6"
                  :class="
                    !alternativeDashboardBoolean
                      ? ''
                      : 'grey--text text--lighten-2'
                  "
                  >Detailed</span
                >
              </div>
            </v-col>
            <v-col cols="4" class="d-flex align-center justify-center">
              <v-switch
                color="secondary"
                inset
                v-model="alternativeDashboardBoolean"
              ></v-switch>
            </v-col>
            <v-col cols="4" class="d-flex align-center justify-center">
              <div class="d-flex flex-column">
                <v-icon v-if="alternativeDashboardBoolean" x-large
                  >mdi-view-dashboard</v-icon
                >
                <v-icon color="grey lighten-2" v-else x-large
                  >mdi-view-dashboard-outline</v-icon
                >
                <span
                  class="text-h6"
                  :class="
                    alternativeDashboardBoolean
                      ? ''
                      : 'grey--text text--lighten-2'
                  "
                  >Simplified</span
                >
              </div>
            </v-col>
          </v-row>

          <v-divider class="my-6 mx-12"></v-divider>
<!--
          <v-row align="center">
            <v-col cols="4" class="d-flex align-center justify-center">
              <div class="d-flex flex-column"> -->
                <!-- @click="colorPickerDialog = true" -->
                <!--
                <v-icon x-large :color="darkModeIconColor"
                  >mdi-rocket-launch</v-icon
                >
                <span class="text-h6" :class="darkModeFontColor">NOS</span>
              </div>
            </v-col>
            <v-col cols="4" class="d-flex align-center justify-center">
              <v-switch color="secondary" inset v-model="lightTheme"></v-switch>
            </v-col>
            <v-col cols="4" class="d-flex align-center justify-center">
              <div class="d-flex flex-column">
                <v-icon x-large>mdi-lightbulb-outline</v-icon>
                <span class="text-h6">Light</span>
              </div>
            </v-col>
          </v-row>
        -->
        <!--<v-divider class="mt-6"></v-divider>-->  

          <v-progress-linear
            v-if="loading"
            indeterminate
            color="accent"
          ></v-progress-linear>

          <div class="mt-8 d-flex justify-space-between">
            <v-btn @click="cancelAll" color="red" outlined>cancel</v-btn>
            <v-btn
              @click="saveSettings"
              depressed
              dark
              :color="darkModeSaveBtnColor"
              width="150"
              >save</v-btn
            >
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="colorPickerDialog"
      max-width="300px"
      transition="dialog-transition"
    >
      <ThemeColorPicker />
    </v-dialog>
  </div>
</template>

<script>
import ThemeColorPicker from "@/components/ThemeColorPicker.vue";
export default {
  name: "AppSettings",
  components: {
    ThemeColorPicker,
  },
  created() {
    this.alternativeDashboardBoolean = this.$store.state.alternativeDashboard;
    this.lightTheme = this.$store.state.lightTheme;
  },
  data() {
    return {
      dialog: false,
      colorPickerDialog: false,
      alternativeDashboardBoolean: null,
      lightTheme: null,
      loading: false,
    };
  },
  methods: {
    cancelAll() {
      this.lightTheme = this.$store.state.lightTheme;
      this.dialog = false;
    },
    openDialog() {
      this.dialog = true;
    },
    saveSettings() {
      this.loading = true;
      setTimeout(() => {
        localStorage.alternativeDashboard = this.alternativeDashboardBoolean;
        localStorage.agentPayLightTheme = this.lightTheme;
        this.$store.commit(
          "SET_ALTERNATIVE_DASHBOARD",
          this.alternativeDashboardBoolean
        );
        this.$store.commit("SET_LIGHT_THEME", this.lightTheme);
        this.loading = false;
        this.dialog = false;
      }, 500);
    },
  },
  computed: {
    darkModeIconColor() {
      if (this.lightTheme) return "grey lighten-2";
      return "accent";
    },
    darkModeFontColor() {
      if (this.lightTheme) return "grey--text text--lighten-2";
      return "accent--text";
    },
    darkModeSaveBtnColor() {
      if (this.lightTheme) return "green";
      return "accent";
    },
  },
  watch: {
    lightTheme(val) {
      if (val) {
        this.$vuetify.theme.light = true;
        this.$vuetify.theme.dark = false;
      } else {
        this.$vuetify.theme.dark = true;
        this.$vuetify.theme.light = false;
      }
    },
  },
};
</script>

<style>
</style>