<template>
  <v-snackbar
      v-model="snackbar"
      color="red"
      fixed
      multiline
      vertical
      :timeout="timeout"
    >
    <p>Error: Contact AgentOS if the issues persists.</p>
    <p>Check your internet connection.</p>
    <v-btn
      color="white"
      text
      @click="snackbar = false"
    >
      Close
    </v-btn>
  </v-snackbar>
</template>

<script>
import {mapState} from 'vuex'

export default {
  data() {
    return {
      snackbar: true,
      timeout: 3000, 
    }
  },
  computed: {
    ...mapState(['errorMessages'])
  },
}
</script>

