<template>
  <div>
    <v-card>
      <v-card-text class="pt-5">
        <v-color-picker
          flat
          dot-size="25"
          swatches-max-height="200"
          v-model="color"
        ></v-color-picker>
        <v-btn @click="saveColor" block depressed color="success">Select</v-btn>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
export default {
  name: 'themeColorPicker',
  created() {
    if(this.$store.state.lightTheme) this.color = this.$vuetify.theme.themes.light.secondary
    else this.color = this.$vuetify.theme.themes.dark.secondary
  },
  data() {
    return {
      color: ''
    }
  },
  methods: {
    saveColor() {
      this.$vuetify.theme.themes.dark.secondary = this.color
    }
  }
}
</script>

<style>

</style>