<template>
  <div app class="main-page tertiary">
    <Navbar v-if="loggedIn"/>
    <v-main class="pb-10">
      <Snackbar v-if="errorMessages.length" class="snackbar" />
      <router-view />
    </v-main>
    <Footer id="footer"/>

    <!-- Logout snackbar -->
    <v-snackbar
      class="mb-12 mx-2"
      color="red"
      v-model="dialog"
      vertical
      :timeout="timeout"
      
    >
      <v-row>
        <v-col cols="12">
          <h2>Auto logout</h2>
          <p>You will be logged out in {{countDown}} seconds...</p>
        </v-col>
        <v-col cols="8" offset="2">
          <v-btn
            block
            color="green"
            dark
            @click="closeDialog"
          >
            Stay logged in?
          </v-btn>          
        </v-col>
      </v-row>
    </v-snackbar>

  </div>
</template>

<script>
import Navbar from '@/components/TheNavbar.vue'
import Footer from '@/components/TheFooter.vue'
import Snackbar from '@/components/Snackbars/SnackbarError.vue'
import {mapState} from 'vuex'
import {mapGetters} from 'vuex'

export default {
  components: {
    Navbar,
    Footer,
    Snackbar
  },
  data() {
    return {
      dialog: false,
      userActivityThrottlerTimeout: null,
      userActivityTimeout: null,
      countDown: 20,
      timeout: 200000
    }
  },
  computed: {
    ...mapState(['errorMessages']),
    ...mapGetters(['loggedIn'])
  },
  mounted() {
    // let zendesk = document.createElement('script')
    // zendesk.setAttribute('src', 'https://v2.zopim.com/?hQmsYpr22Lyxmrakbvh94szVGC7lqQIe')
    // document.head.appendChild(zendesk)
  },
  created() {
    this.$store.commit("EXPIRE_LOGIN");
    console.log(this.$store.state.user);
      if(this.$store.state.user !== null){
      this.$store.dispatch('sessionStatus')
      .then( () => {
        // this.$store.dispatch('dispatchActions')
        // this.$store.dispatch('fetchDashboardData')
        this.$store.dispatch('getSalesChargeTypes')
        this.$store.dispatch('getNominalCodes')
      })
    }
    else{
      this.$router.push('/');
    }
  
  },
  methods: {
    // Detecting user interraction - used in auto logout
    activateActivityTracker() {
      window.addEventListener("mousemove", this.userActivityThrottler)
      window.addEventListener("scroll", this.userActivityThrottler)
      window.addEventListener("keydown", this.userActivityThrottler)
      window.addEventListener("resize", this.userActivityThrottler)
    },
    resetUserActivityTimeout() {
      // console.log('resetting user activity timeout')
      clearTimeout(this.userActivityTimeout)
      this.userActivityTimeout = setTimeout(() => {
        this.inactiveUserAction()
        // 1 min = 60000
        // 3 mins = 180000
        //10 mins = 600000
        //20 mins = 1200000
      }, 1200000);
    },
    userActivityThrottler() {
      if (!this.userActivityThrottlerTimeout) {
          this.userActivityThrottlerTimeout = setTimeout(() => {
          this.resetUserActivityTimeout()
          clearTimeout(this.userActivityThrottlerTimeout)
          this.userActivityThrottlerTimeout = null
        }, 1000)
      }
    },
    inactiveUserAction() {
      // logout logic
      // this.isInactive = true
      this.dialog = true
      this.countDownTimer()
    },
    countDownTimer() {
      if(this.dialog && this.countDown > 0) {
          setTimeout(() => {
              this.countDown -= 1
              this.countDownTimer()
          }, 1000)
      }
    },
    closeDialog() {
      this.dialog = false
      // this.isInactive = false
      this.countDown = 21
    }
  },
  watch: {
    countDown(val) {
      if(val === 0) {
        this.$store.dispatch('logout').then(() => {
          this.dialog = false
          this.countDown = 20
          this.$router.push('/')
        })
      }
    },
  },
  beforeMount() {
    this.activateActivityTracker();
  },
  beforeDestroy() {
    window.removeEventListener("mousemove", this.userActivityThrottler);
    window.removeEventListener("scroll", this.userActivityThrottler);
    window.removeEventListener("keydown", this.userActivityThrottler);
    window.removeEventListener("resize", this.userActivityThrottler);
  
    clearTimeout(this.userActivityTimeout);
    clearTimeout(this.userActivityThrottlerTimeout);
  },  
}
</script>

<style>
  .snackbar {
    margin-bottom: 3rem;
  }
  .main-page{
    height: 100%;
  }
  #footer {
     position: absolute;
     bottom: 0;
     width: 100%;
  }
</style>
