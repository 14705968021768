<template>
  <div>
    <v-card height="0">
      <v-footer padless class="tertiary lighten-2 font-weight-medium">
        <v-row align="center">
          <v-col
            cols="12"
            class="d-flex flex-column justify-center align-center pt-8"
          >
            <div class="">
              <v-img
                v-if="$store.state.lightTheme"
                src="@/assets/agentPay Logos/Logo/SVG/agentPay - Logo.svg"
                contain
                width="70"
              ></v-img>
              <v-img
                v-else
                src="@/assets/agentPay Logos/Logo/SVG/agentPay - darkLogo.svg"
                contain
                width="70"
              ></v-img>
            </div>
            <p class="mt-1 text-caption">
              &copy;{{ new Date().getFullYear() }} v1.3.0
            </p>
          </v-col>
        </v-row>
      </v-footer>
    </v-card>
  </div>
</template>

<script>
export default {

}
</script>

